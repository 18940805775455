import React from "react";
import { Helmet } from "react-helmet";
import Contacts from "./Contacts";
import Landing from "./Landing";
import Navbar from "./NavBar";
import About from "./About";
import Education from "./Education";
import Experience from "./Experience";
import Skills from "./Skills";
import Certificate from "./Certificate";
import { headerData } from "../utils/utils";
import Projects from "./Projects";

function Home() {
  return (
    <div>
      <Helmet>
        <title>{headerData.name} - Porfolio</title>
      </Helmet>
      <Navbar />
      <Landing />
      <About />
      <Experience />
      <Skills />
      <Certificate />
      <Projects />
      <Education />
      <Contacts />
    </div>
  );
}

export default Home;
