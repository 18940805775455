import myImg from "../assets/images/about.jpeg";
import creators4u from "../assets/images/creators4u.png";
import infy from "../assets/images/infy.png";
import tcs from "../assets/images/tcs.png";
import cousera from "../assets/images/coursera.jpeg";
import nptel from "../assets/images/nptel.jpeg";
import udacity from "../assets/images/udacity.png";
import wbp from "../assets/images/wbp.png";
import incident from "../assets/images/incident.jpeg";
import onboard from "../assets/images/onboard.png";
import eskill from "../assets/images/eskill.jpeg";
import farmdict from "../assets/images/farmdict with tag.jpg";
import balostav from "../assets/images/balotsav.png";
import cnn from "../assets/images/machinel.jpg";

import myResume from "../assets/documents/Bhimavarapu Sravya Pranati.pdf";
export const headerData = {
  name: "Sravya Pranati ",
  title: "Hello! I am",
  desciption:
    "Hello! I am Sravya Pranati, An Enthusiastic towards 4T's Technologies, Trends, Things, Tours. Developing Crazy Apps,Websites is my Passion. A programming language or a technology is never a barrier for Execution of My Ideas. I am on My way...",
  image: myImg,
  resumePdf: myResume,
};

export const contactsData = {
  email: "sravyapranathi333@gmail.com",
  github: "https://github.com/SravyaPranati",
  linkedIn: "https://www.linkedin.com/in/bhimavarapu-sravya-pranati/",
};

export const aboutData = {
  title: "Who I am",
  description1: `A Dynamic Professional of 3+ years of successful IT experience in design and configuration for implementation, development, maintenance and support as a Software Developer.Specializing in
      programming, with hands-on awareness on Web Applications and Mobile Applications coupled with
      passion-driven individual. I strive to exceed expectations. I enjoy researching and solving complex
      problems at the intersection of the technical and social.`,
};

export const experienceData = [
  {
    id: 1,
    company: "Infosys Limited",
    jobtitle: "Senior Associate Consultant",
    startYear: "Mar 2022",
    endYear: "Present",
    image: infy,
  },
  {
    id: 2,
    company: "Tata Consultancy Services",
    jobtitle: "Software Developer",
    startYear: "Nov 2020",
    endYear: "Mar 2022",
    image: tcs,
  },
  {
    id: 3,
    company: "Creators4u",
    jobtitle: "React JS Developer",
    startYear: "Mar 2020",
    endYear: "Oct 2020",
    image: creators4u,
  },
];

export const skillsData = [
  "HTML",
  "Figma",
  "Javascript",
  "CSS",
  "React",
  "Next JS",
  "Node JS",
  "Bootstrap",
  "MongoDB",
  "Tailwind",
  "MySQL",
  "MaterialUI",
  "Firebase",
  "Python",
  "Java",
  "Git",
];

export const certificateData = [
  {
    id: 1,
    company: "Udacity",
    certificates: [
      "ReactJS Developer Nano Degree",
      "Android Developer Nano Degree",
      "Machine Learning Nano Degree",
    ],
    image: udacity,
  },
  {
    id: 2,
    company: "NPTEL",
    certificates: [
      "Programming, Data Structures, and Algorithms using Python",
      "R software",
      "Problem-Solving through programming in C & JAVA",
      "Database Management Systems",
    ],
    image: nptel,
  },
  {
    id: 3,
    company: "Coursera",
    certificates: ["Deep Learning Specialisation"],
    image: cousera,
  },
];

export const projectsData = [
  {
    id: 1,
    projectName: "Banking Customer",
    projectDesc: `Web based banking, on the other
      hand, is a versatile and interactive tool that various devices`,
    tags: ["React JS, Redux Saga, HTML, CSS, Javascript, NodeJs,Jest,Enzyme"],
    image: wbp,
  },
  {
    id: 2,
    projectName: "Incident Automation Tool",
    projectDesc: `Incident automation helps the monitoring team by
      providing an auto resolution.`,
    tags: ["ReactJS", "HTML", "CSS", "Javascript"],
    image: incident,
  },
  {
    id: 3,
    projectName: "Onboarding OffBoarding",
    projectDesc: `To onboard or offboard a user in an organization.`,
    tags: ["React JS", "HTML", "CSS", "Javascript", "Python"],
    image: onboard,
  },
  {
    id: 4,
    projectName: "eSkill",
    projectDesc: `The student can learn the courses from this web site by
    enrolling them. After completion of the course the user will get the certificate.`,
    tags: ["React JS, HTML, CSS, Javascript"],
    image: eskill,
  },
  {
    id: 5,
    projectName: "Farm Dict",
    projectDesc: `Farmer's Dictionary, Using this app farmers can get all information regarding farming in Telugu and
    English languages.`,
    tags: ["Android"],
    demo: "https://play.google.com/store/apps/details?id=com.creatorsforu.appone.farmdict&hl=en",
    image: farmdict,
  },
  {
    id: 6,
    projectName: "VVIT BALOTSAV",
    projectDesc: `VVIT Balotsav is an International Telugu Kids Festival. In order to register for the events app is used and
    results are visible in the application.`,
    tags: ["Android"],
    demo: "https://play.google.com/store/apps/details?id=app.balotsav.com.vvitbalotsav",
    image: balostav,
  },
  {
    id: 7,
    projectName: "Large Scale Video Classification with CNN",
    projectDesc: `In this project, we performed Youtube analysis on the YouTube 8M dataset. So that any video can be
    analyzed in 10 min to which category it belongs to.`,
    tags: ["Machine Learning"],
    image: cnn,
  },
];

export const educationData = [
  {
    id: 1,
    institution: "Vasireddy Venkatadri Institute of Technology",
    course: "Bachelor of Technology in Computer Science & Engineering",
    startYear: "2016",
    endYear: "2020",
  },
  {
    id: 2,
    institution: "Sri Chaitanya MMP Junior College",
    course: "Higher Secondary",
    startYear: "2016",
    endYear: "2014",
  },
  {
    id: 3,
    institution: "Keshava Reddy EM High School",
    course: "Secondary School",
    startYear: "2013",
    endYear: "2014",
  },
];
