import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import { ThemeContext } from "./contexts/theme-context";
import BackToTop from "./components/BackToTop";
import ChangeTheme from "./components/ChangeTheme";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/Home";
import ProjectPage from "./components/ProjectPage";

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: theme.secondary }}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/projects" element={<ProjectPage />} exact />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
      <BackToTop />
      <ChangeTheme />
    </div>
  );
}

export default App;
