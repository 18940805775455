import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";
import React, { useContext } from "react";
import Slider from "react-slick";
import { ThemeContext } from "../contexts/theme-context";
import "./css/certificate.css";
import { certificateData } from "../utils/utils";

const Certificate = () => {
  const { theme } = useContext(ThemeContext);

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container sx={{ py: 5 }}>
      <div className="certificate-header">
        <h1
          style={{
            color: theme.primary,
          }}
        >
          Certificates
        </h1>
      </div>
      <Slider {...settings}>
        {certificateData.map((item) => (
          <Card key={item.id} sx={{ height: { xs: 200, sm: 300 } }}>
            <CardActionArea>
              <CardMedia
                component="img"
                sx={{
                  height: { xs: 100, sm: 200 },
                  borderBottom: "1px solid #f5f5f5",
                }}
                image={item.image}
                alt={item.company}
              />
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ul>
                  {item.certificates.map((cert, index) => {
                    return (
                      <li
                        style={{ fontSize: "14px", fontFamily: "sans-serif" }}
                        key={index}
                      >
                        {cert}
                      </li>
                    );
                  })}
                </ul>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Slider>
    </Container>
  );
};

export default Certificate;
