// import { contactsImage } from "./images";

export const theLightTheme = {
  type: "light",
  primary: "#0ca1e7",
  quaternary: "#EFF3F4",
  quaternaryLight: "#F7F9F9",
  secondary: "#ffffff",
  tertiary: "#0f1419",
  buttonColor: "#0F1419",
  // contactsimg: contactsImage,
};

export const theDarkTheme = {
  type: "dark",
  primary: "#0ca1e7",
  secondary: "#0e2c3a",
  quaternary: "#1e4871",
  quaternaryLight: "#8b98a5",
  tertiary: "#EFF3F4",
  buttonColor: "#8B98A5",
  // contactsimg: contactsImage,
};
