import React from "react";
import Typed from "react-typed";
import "../css/landing.css";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { headerData, contactsData } from "../../utils/utils";

function LandingUI({ theme, drawerOpen, classes }) {
  return (
    <div
      className="landing"
      style={{
        backgroundColor:
          "-webkit-linear-gradient(225deg, rgb(132, 0, 255) 0%, rgb(230, 0, 255) 100%)",
      }}
    >
      <div className="landing--container">
        <img
          src={headerData.image}
          alt=""
          className="landing--img"
          style={{
            opacity: `${drawerOpen ? "0" : "1"}`,
            borderColor: theme.primary,
          }}
        />
        <div
          className="landing--container-right"
          style={{ backgroundColor: theme.secondary }}
        >
          <div className="lcr--content" style={{ color: theme.tertiary }}>
            <h1>{headerData.name}</h1>
            <Typed
              strings={[
                "Fullstack Developer",
                "Backend Developer",
                "Frontend Developer",
              ]}
              typeSpeed={40}
              backSpeed={50}
              className="typed-header"
              style={{ color: theme.primary, fontSize: "20px" }}
              loop
            />
            <p>{headerData.desciption}</p>

            <div className="lcr-buttonContainer">
              {headerData.resumePdf && (
                <a
                  href={headerData.resumePdf}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={classes.resumeBtn}>Check Resume</button>
                </a>
              )}
              <div className="lcl--content">
                {contactsData.linkedIn && (
                  <a
                    href={contactsData.linkedIn}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin
                      className="landing--social linkedin-icon"
                      style={{ color: theme.primary }}
                      aria-label="LinkedIn"
                    />
                  </a>
                )}
                {contactsData.github && (
                  <a
                    href={contactsData.github}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaGithub
                      className="landing--social"
                      style={{ color: theme.primary }}
                      aria-label="GitHub"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingUI;
