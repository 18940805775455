import { Container } from "@mui/material";
import React, { useContext } from "react";
import experience from "../assets/lottie/development.json";
import { ThemeContext } from "../contexts/theme-context";
import AnimationLottie from "./AnimationLottie";
import ExperienceCard from "./ExperienceCard";
import "./css/experience.css";
import { experienceData } from "../utils/utils";

function Experience() {
  const { theme } = useContext(ThemeContext);
  return (
    <div style={{ backgroundColor: theme.secondary }}>
      <Container className="experience" id="experience">
        <div className="experience-body">
          <div className="experience-image">
            <AnimationLottie animationPath={experience} />
          </div>
          <div className="experience-description">
            <h1 style={{ color: theme.primary }}>Experience</h1>
            {experienceData.map((exp) => (
              <ExperienceCard
                key={exp.id}
                id={exp.id}
                jobtitle={exp.jobtitle}
                company={exp.company}
                startYear={exp.startYear}
                endYear={exp.endYear}
                image={exp.image}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Experience;
